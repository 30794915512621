<template>
  <div class='bodyContent' style='display: block;'>
    <div class='Home'>
      <div id='HomeContributeArea' class='ContributeArea'>
        <div class='child-main'>
          <el-row>
            <router-link :to="{name: 'walletcreat',query:{chain:'bsc'}}">
              <el-button>bsc</el-button>
            </router-link>
            <router-link :to="{name: 'walletcreat',query:{chain:'eth'}}">
              <el-button>eth</el-button>
            </router-link>
          </el-row>
          <div>批量生成{{ $route.query.chain }}地址</div>
          <div>
            <span class='el-icon-s-flag'></span>
            钱包生成过程均在本地电脑完成，我们无法获取到您的助记词及私钥！
          </div>
          <div>

          </div>
          <el-row :gutter='20'>
            <el-col :span='18'>
              <div class='grid-content bg-purple'>
                <el-input v-model='walletnum' type='number' placeholder='请输入钱包个数'></el-input>
              </div>
            </el-col>
            <el-col v-if='wallet_arr.length ==0' :span='6' >
              <div class='grid-content bg-purple' style='text-align: left;'>
                <el-button @click='creatWallet()'>生成钱包</el-button>
              </div>
            </el-col>
            <el-col v-if='wallet_arr.length !=0' :span='3'>
              <div class='grid-content bg-purple' style='text-align: left;'>
                <el-button @click='creatWallet()'>生成钱包</el-button>
              </div>
            </el-col>
            <el-col v-if='wallet_arr.length !=0' :span='3'>
              <div class='grid-content bg-purple' style='text-align: left;'>
                <exportExcel :id="'exportTab'" :name="'导出Table'"></exportExcel>
              </div>
            </el-col>
          </el-row>
          <el-table
            v-if='wallet_arr.length !=0'
            id="exportTab"
            :data='wallet_arr'
            height='800'
            border
            style='width: 100%;margin-top: 15px;'>
            <el-table-column
              prop='address'
              label='钱包地址'
              width='375'>
            </el-table-column>
            <el-table-column
              prop='privateKey'
              label='私钥'
            >
            </el-table-column>
            <el-table-column
              prop='phrase'
              label='助记词'>
            </el-table-column>
          </el-table>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Web3 from 'web3'
import * as bip39 from 'bip39'
import ExportExcel from '@/components/ExportExcel.vue'

export default {
  name: 'WalletCreat',
  components: { ExportExcel },
  validate({ query }) {
    if (query.chain === 'bsc') {
      this.web3provider = 'https://data-seed-prebsc-1-s1.binance.org:8545/'
    }
    if (query.chain === 'eth') {
      this.web3provider = 'https://mainnet-eth.compound.finance'
    }
    return query.chain === 'bsc' || query.chain === 'eth'
  },
  data() {
    return {
      walletnum: '',
      web3: '',
      web3provider: '',
      wallet_arr: []
    }
  },
  head(){
    return {
      title: '批量创建钱包',
    }
  },
  watch: {
    walletnum() {
      // eslint-disable-next-line no-console
      console.log(typeof this.walletnum)
    }
  },
  // eslint-disable-next-line require-await
  async mounted() {
    this.checkWeb3()
    window.web3 = new Web3(this.web3provider)
    this.web3 = new Web3(this.web3provider)
  },
  methods: {
    creatWallet() {
      if (this.web3 === null) return
      this.total = this.walletnum
      for (let i = 0; i < this.walletnum; i++) {
        const wallet = this.web3.eth.accounts.create()
        const mnemonic = bip39.generateMnemonic()
        this.wallet_arr.push({
          address: wallet.address,
          privateKey: wallet.privateKey,
          phrase: mnemonic
        })
      }
    },
    checkWeb3() {
      const web3 = window.web3
      if (typeof web3 === 'undefined') {
        this.web3 = null
      }
    }

  }


}
</script>
<style>



</style>
